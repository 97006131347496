@import '../App/InvestoristColours';

.formLabel {
  margin-bottom: 5px;
  text-align: left;
}

.publicProjectEnquiryDialog {
  textarea {
    outline: none;
    resize: none;
    overflow: auto;
    width: 100%;
    padding: 20px;
    border: 1px solid $colour-grey-dark;
  }

  .modal-body {
    padding: 0 30px 30px;
  }

  p {
    text-align: left;
  }

  .formLabel {
    color: $colour-secondary-mid;
  }

  .labelDesc {
    font-size: 12px;
  }

  .flagIcon {
    max-width: 20px;
    max-height: 20px;
  }

  .react-phone-number-input__icon {
    border: none;
    width: 25px;
    margin-top: -20px;
  }

  .react-phone-number-input__input.react-phone-number-input__phone::placeholder {
    color: $colour-input-placeholder-text;
  }

  .react-phone-number-input__country-select {
    height: 20px;
    margin-top: -10px;
  }

  .react-phone-number-input__country-select-arrow {
    margin-top: -10px;
  }

  .react-phone-number-input__icon-image {
    max-height: none;
  }

  .react-phone-number-input__input  {
    margin-top: -10px;
    border-bottom: none;
  }

  .react-phone-number-input--focus  {
    border-color: $colour-input-focus;
  }

  @media (min-width: 992px) {
   .modal-dialog {
      width: 650px;
    }
  }
}

