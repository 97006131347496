.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pageError {
  height: 85vh !important;
}

.error p,
.error button {
  margin: 5px 0;
}

.error img {
  height: 80px;
  width: 80px;
}
