@import './InvestoristColours';

// font styles
body,h1, h2, h3, h4, h5, h6, p, ul, li, b, u, i, th, td {
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4 {
  font-weight: bold;
  color: $colour-primary-dark;
}

h5, h6 {
  color: $colour-grey-darkest;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 14px;
}

h4 {
  font-size: 12px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

hgroup {
  text-align: center;
  margin-bottom: 20px;
}

hgroup h2 {
  font-size: 36px;
  font-weight: bold;
}

hgroup h2 + h3 {
  color: $colour-grey-darkest;
  font-size: 14px;
  font-weight: normal;
  margin-top: 10px;
}

p, ul, li {
  font-size: 14px;
  color: $colour-grey-darkest
}

b {
  font-weight: bold;
  color:$colour-primary-dark;
}

.darkGreyP {
  color: $colour-secondary-dark;
}

.darkGreyPBold {
  color: $colour-secondary-dark;
  font-weight: bold;
}

.greyBtn, .greyBtn:hover {
  background-color: $colour-white-pure !important;
  color: $colour-grey-darkest !important;
  border: 1px solid $colour-grey-mid !important;
}

.disableBtn, .disableBtn:hover {
  cursor: not-allowed;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.callToActionBtn {
  color: $colour-white-pure !important;
  border: 0 !important;
  outline: none !important;
  background-color: var(--secondary-brand-colour) !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.buttonAsLink {
  text-decoration: underline;
  background-color: $colour-white-pure !important;
  font-weight: bold !important;
  height: 100%;
}
.buttonAsLink:hover {
  text-decoration: underline !important;
  background-color: $colour-white-pure !important;
}
.greyText, .greyText:hover {
  color: $colour-grey-darkest !important;
}
.greenText, .greenText:hover {
  color: var(--secondary-brand-colour) !important;
}
.button, button, .button.btn-default, button.btn-default {
  border: 0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
}

.button, button, .button.btn-default, button.btn-default,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.button,
button,

.btn-default.ac,

.button.btn-default,
button.btn-default,

.btn-default.disabled:hover,
.btn-default:hover,
.btn-default[disabled]:hover,
.button.btn-default:hover,
button.btn-default:hover,

.btn-default.focus,
.btn-default:focus,

.button.btn-default.active,
.button.btn-default:active,
button.btn-default.active,
button.btn-default:active,

.button.btn-default.active.focus,
.button.btn-default.active:focus,
.button.btn-default.focus:active,
.button.btn-default:active:focus,
button.btn-default.active.focus,
button.btn-default.active:focus,
button.btn-default.focus:active,
button.btn-default:active:focus
{
  color: $colour-white-pure;
  background-color: var(--secondary-brand-colour);
}

button p {
  color: $colour-white-pure !important;
}

button p.subText {
  font-weight: normal;
}

input, textarea, .form-control {
  border-color: $colour-grey-light;
}

input:focus, textarea:focus, .form-control:focus {
  border-color: #66afe9;
}

legend {
  border-bottom: none;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.whiteButton {
  color: $colour-grey-darkest !important;
  border: 1px solid $colour-grey-light !important;
  background-color: $colour-white-pure !important;
  border-radius: 2px !important;
}

.whiteButton:hover {
  background-color: $colour-grey-mid !important;
}

.whiteButton:disabled {
  background-color: $colour-grey-light !important;
}
table th {
  color: $colour-grey-darkest;
}
table td {
  font-weight: bold;
}
.mainBrandColour {
  color: var(--main-brand-colour);
}
@media (max-width:767px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  p {
    font-size: 12px;
  }
}