@import url('https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800');
@import 'InvestoristColours';

html {
  background-color: $colour-page-background;
}

#root {
  background-color: $colour-page-background;
  min-height: 100%;
  margin-left: auto;
  margin-right: auto;
}

:root {
  --secondary-brand-colour: #{$colour-primary-accent};
  --main-brand-colour: #{$colour-primary};
  --main-brand-colour-dark: #{$colour-secondary-dark};
  --secondary-brand-colour-dark: #337ab7;
  --secondary-brand-colour-dark-highlight: #{$colour-primary-accent};
}

p {
  margin: 0;
}

input::placeholder, textarea::placeholder, .form-control::placeholder {
  color: $colour-input-placeholder-text;
}

.readMoreLink {
  color: $colour-grey-darkest;
  text-decoration: underline;
  font-size: 12px;
  display: block;
}

// margin styles
.noBump {
  margin: 0 !important;
}
.noBumpTop {
  margin-top: 0 !important;
}
.bumpLeftHard {
  margin-left: 40px;
}
.bumpLeft {
  margin-left:20px;
}
.bumpLeftTiny {
  margin-left: 5px;
}
.bumpLeftHalf {
  margin-left: 10px;
}
.bumpTop {
  margin-top: 20px;
}
.bumpTopHalf {
  margin-top: 10px;
}
.bumpTopTiny {
  margin-top:5px;
}
.bumpBottom {
  margin-bottom: 20px;
}
.bumpBottomHalf {
  margin-bottom: 10px;
}
.bumpBottomTiny {
  margin-bottom: 5px;
}
.bumpBottomHard {
  margin-bottom: 40px;
}
.bumpRight {
  margin-right: 20px;
}
.bumpRightTiny {
  margin-right: 5px;
}
.noPadding {
  padding: 0;
}
.noPaddingLeft {
  padding-left:0;
}

.noPaddingRight {
  padding-right:0;
}
.paddingRightHalf {
  padding-right: 10px;
}
.paddingLeftTiny {
  padding-left:5px;
}
.paddingRightTiny {
  padding-right:5px;
}

//icon styles
.iconDesc {
  padding-left: 5px;
  margin-top: 3px;
}

.pageContent {
  margin-top: 40px;
  background-color: white;
  padding: 30px;
}

.sectionHeading {
  margin-top: 30px;
}
.sectionContent {
  margin-top: 20px;
}
.subHeading {
  margin-top:5px;
}

h1,h2,h3,h4,h5 {
  margin:0;
}
hr {
  margin-bottom:0;
}
.normalFont {
  font-weight: normal !important;
}
.boldFont {
  font-weight: bold;
}
.biggerText {
  font-size: 16px;
}
.whiteText {
  color: $colour-white-text;
}
.smallerText {
  font-size: 12px;
}
.truncateTextOverflow{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.textNoWrap {
  white-space: nowrap;
}
.textLeft {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.smallFont {
  font-size: 12px !important;
}
.floatRight {
  float: right;
}
.errorMessage {
  color: red;
}
.redInputBorder {
  border: 2px solid red !important;
}
.erroneous {
  border: 2px solid red;
}
.availableStatus {
  color: $colour-primary-accent;
}
.pendingStatus {
  color: $colour-red-speaking-of-the-devil;
}
.flexContainer {
  display: flex;
  flex-wrap: wrap;
}
.flexItems {
  flex-direction: column;
}
.flexContainer.row:before, .flexContainer.row:after {
  display: inline-block;
}
.vAlignMiddle {
  margin-top: auto;
  margin-bottom: auto;
}
.commissionCallToAction, .commissionCallToAction:hover {
  padding: 0 !important;
  background-color: #fff !important;
  font-weight: bold !important;
}
.commissionCallToAction:active {
  box-shadow: none !important;
}
.modal-header .close {
  color: $colour-secondary-dark;
  background-color: #fff;
  font-size: 40px;
}
.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.modal-body {
    padding: 0 40px 40px;
}
.form-control {
  padding:21px 12px;
  border-radius: 0;
  box-shadow: none;
}

.featuredLabel {
  background-color: var(--main-brand-colour);
}

.italicFont {
  font-style: italic;
}

.iconBackground {
  padding: 3px;
  text-align: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.iconBackground .icon {
  margin-right:0;
}

.projectImagePlaceholder {
  text-align: center;
  background-color: #fff;
  padding: 20px;
}

.projectImagePlaceholder img {
  height: 500px;
}

.publicButtonWrap .loginButton {
  color: var(--secondary-brand-colour) !important;
  background-color: white !important;
  border: 1px solid var(--secondary-brand-colour) !important;
}

.getInTouchBtn {
  border: 1px solid var(--secondary-brand-colour) !important;
}

.phoneSection img {
  max-width: 25px;
  max-height: 25px;
}

.phoneSection .phoneNumber {
  font-size: 12px;
}

.btn.reviewPending {
  background-color: $colour-grey-darkest !important;
}

// media queries
@media (min-width: 992px)
{
  .customWidth {
    margin-left: 3.333333%;
    width:30%;
  }
  .hideScrollBar {
    overflow: hidden;
  }
  .modal-dialog {
    width: 700px;
  }
  .pull-right-desktop {
    float: right;
  }
}
@media (max-width:768px) {
  #root {
    overflow: hidden;
  }
  .modal button.close {
    text-align: right;
  }
  .responsive-table {
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding-bottom: 20px;
    overflow-x: auto;
}
  .noPaddingMobile {
    padding: 0;
  }
  .noPaddingLeftMobile {
    padding-left:0;
  }
  .bumpTopMobile {
    margin-top: 20px;
  }
  #intercom-container .intercom-launcher-frame.intercom-launcher-frame-mobile,
  #intercom-container .intercom-launcher-discovery-frame {
    bottom: 65px !important;
  }
}

#intercom-container {
  z-index: 40000 !important;
}

#__talkjs_launcher {
  z-index: 50000 !important;
}

.anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

.statusPage {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
}

.statusPage > * {
  margin: 10px;
}

.statusPage button {
  padding: 10px 20px;
  margin: 10px 10px;
}
