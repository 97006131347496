@import '../App/InvestoristColours';

.alertPopup {
  display: flex !important;
  align-items: center;
}

// Message success/failure popup
@media (min-width: 992px) {
  .alertPopup .modal-dialog {
    width: 700px !important;
  }
}

.alertPopup .modal-header {
  width: 100%;
  border-bottom: none;
  z-index: 100;
  padding: 15px 15px 0;
}

.alertPopup .modal-header .close {
  color: $colour-secondary-dark !important;
  background-color: #fff !important;
  font-size: 40px !important;
}

.alertPopup .modal-body {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 50px 50px 50px;
}

.alertPopup .modal-body h1,.alertPopup .modal-body p {
  margin-top: 20px;
}

.alertPopup .modal-body button {
  margin-top: 35px;
  padding: 10px;
}

.alertPopup .modal-body button.success,
.alertPopup .modal-body button.success:hover {
  background-color: var(--secondary-brand-colour);
}

.alertPopup .modal-body button.failure,
.alertPopup .modal-body button.failure:hover {
  background-color: $colour-alternate !important;
}

.alertPopup .modal-body button.visitInvestorist {
  background-color: $colour-primary-accent !important;
}

.alertPopup .modal-body button.visitInvestorist:hover {
  background-color: $colour-primary-accent !important;
}

.alertPopup .iconBackground {
  margin: 0;
  padding: 10px;
  text-align: center;
  border-radius: 100%;
  width: 70px;
  height: 70px;
}

.alertPopup .icon {
  fill: var(--secondary-brand-colour);
  height: 50px;
  width: 50px;
}
