@import '../App/InvestoristColours';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: $colour-white-pure;
}

.loader img,
.loader p,
.loader button {
  margin: 10px 0;
}

.pageLoader {
  height: 90% !important;
  background-image: url(loading-generic.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
}

.loader img {
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite; /* Safari */
}

.searchResultsPage .pageLoader {
  background-image: url(loading-screen-map-results.png);
}

.projectPage .pageLoader, .stockPage .pageLoader {
  background-image: url(loading-project-property.png);
}

.loaderContent {
  text-align: center;
  padding: 0 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@media (min-width: 992px)
{
  .loader p {
    width: 380px;
    text-align: center;
  }
}

@media (max-width: 768px)
{
  .pageLoader {
    background-image: url(loading-screen-generic-mobile@2x.png);
  }
  .searchResultsPage .pageLoader {
    background-image: url(loading-results-mobile@2x.png);
  }

  .projectPage .pageLoader, .stockPage .pageLoader {
    background-image: url(loading-screen-project-mobile@2x.png);
  }
}