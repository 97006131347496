// Please specify new colours here, feel free to use the website https://colornamer.netlify.com/ to make the name descriptive.
// Format is colour-<base colour>-<description>
$colour-grey-darkest: #77777A;
$colour-grey-dark: #B2B2B5;
$colour-grey-mid: #D3D2D3;
$colour-grey-light: #EDEDED;
$colour-grey-lightest: #F6F6F6;
$colour-white-pure: #FFFFFF;
$colour-white-whats-left: #FEF4E9;
$colour-white-smoke: #F5F5F5;
$colour-white-just-pink-enough: #FFEBEF;
$colour-grey-equilibrium: #A3A0A0;
$colour-grey-platinum-granite: #7F7F7F;
$colour-grey-davys: #555555;
$colour-grey-wrought-iron-gate: #48484A;
$colour-black-jet: #2D2D30;
$colour-green-buddha: #2FBF71;
$colour-green-nordic-grass: #23A35D;
$colour-orange-dragon-ball: #F89927;
$colour-orange-sports-fan: #D57F19;
$colour-red-infrared-tang: #DB244B;
$colour-red-radish-lips: #EF2D56;
$colour-red-speaking-of-the-devil: #A93F55;
$colour-blue-sanctuary-spa: #66AFE9;
$colour-blue-vivid-cerulean: #00ACED;
$colour-blue-sweedish: #007FB1;
$colour-blue-lambent-lagoon: #3B5998;
$colour-blue-dazzling: #3B51A3;

// Overarching styling colors
$colour-primary: $colour-orange-dragon-ball;
$colour-primary-accent: $colour-green-buddha;
$colour-primary-dark: $colour-grey-wrought-iron-gate;
$colour-secondary: $colour-orange-sports-fan;
$colour-secondary-accent: $colour-green-nordic-grass;
$colour-secondary-dark: $colour-black-jet;
$colour-secondary-mid: $colour-grey-wrought-iron-gate;
$colour-alternate: $colour-red-radish-lips;
$colour-alternate-dark: $colour-red-infrared-tang;
$colour-input-text: $colour-grey-davys;
$colour-input-placeholder-text: $colour-grey-equilibrium;
$colour-input-focus: $colour-blue-sanctuary-spa;

$colour-page-background: $colour-grey-lightest;
$colour-header-background: $colour-white-pure;
$colour-footer-background-top: $colour-white-pure;
$colour-footer-background-mid: $colour-primary-dark;
$colour-tile-background: $colour-white-pure;
$colour-error: $colour-red-radish-lips;
$colour-timeline-status: $colour-red-speaking-of-the-devil;
$colour-white-text: $colour-white-pure;

// The :export directive allows webpack to access these colours in a javascript object.
:export {
  greyDarkest: $colour-grey-darkest;
  greyDark: $colour-grey-dark;
  greyMid: $colour-grey-mid;
  greyLightest: $colour-grey-lightest;
  whitePure: $colour-white-pure;
  redSpeakingOfTheDevil: $colour-red-speaking-of-the-devil;
  blueDazzling: $colour-blue-dazzling;

  primary: $colour-primary;
  primaryAccent: $colour-primary-accent;
  primaryDark: $colour-primary-dark;
  secondaryAccent: $colour-secondary-accent;
  secondaryDark: $colour-secondary-dark;
  alternate: $colour-alternate;
};
