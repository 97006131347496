@import '../App/InvestoristColours';

.banner {
  position: absolute;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  top: 50px;
  width: 100%;
  height: 50px;
  z-index: 1000;
  background-color: $colour-alternate;
}

.banner p {
  color: white;
  margin: 0 20px;
}

.banner button {
  color: $colour-alternate !important;
  background-color: white !important;
}

.banner button:hover {
  color: white !important;
  background-color: $colour-alternate-dark !important;
}
