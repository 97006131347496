@import '../App/InvestoristColours';

.limitedAccessBannerWrap {
  margin-left: -15px;
  margin-right: -15px;
}

.limitedAccessBanner {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 10px 20px;
  background-color: $colour-primary;
}

.limitedAccessBanner p {
  color: white;
}
