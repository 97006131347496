@import '../App/InvestoristColours';

.icon {
  vertical-align: middle;
  margin-right: 5px;
}

.title-icon {
  width: 50px;
  height: 50px;  
  fill: $colour-primary-accent;
}