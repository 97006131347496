@import '../App/InvestoristColours';

.accept_cookies_modal {
  .modal-dialog {
    width: 900px;
    margin: calc(50vh - 143px) auto;
    .modal-content {
      background-color: var(--main-brand-colour);
      .modal-body {
        padding: 15px;
      }
    }
  }
}

.accept_cookies_popup_wrapper {
  margin-left: -15px;
  margin-right: -15px;
}

.accept_cookies_popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  background-color: var(--main-brand-colour);
}

.accept_cookies_column {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  p {
    color: white;
    text-align: center;
  }

  .main_text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      font-size: 18px;
    }
  }

  .secondary_text p {
    padding-top: 20px;
    font-size: 14px;
  }
}

.accept_cookies_buttons {
  padding-top: 25px;
  padding-bottom: 10px;

  .decline_cookies.btn {
    color: var(--main-brand-colour);
    border: 1px solid $colour-white-pure;
    background-color: $colour-white-pure;
  }
  .decline_cookies.btn:hover {
    background-color: $colour-grey-light;
  }

  .accept_cookies.btn {
    color: $colour-white-pure;
    background-color: $colour-primary-accent;
    border: 1px solid $colour-primary-accent;
  }
  .accept_cookies.btn:hover {
    border: 1px solid $colour-white-pure;
  }
}

.accept_cookies_toggle {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
  margin-left: 10px;
  margin-bottom: -20px;
  
  .toggle_choice.btn {
    background-color: var(--main-brand-colour);
    color: $colour-white-pure;
    text-decoration-line: underline;
    box-shadow: none;
  }
  .toggle_choice.btn:hover,
  .toggle_choice.btn:active {
    background-color: var(--main-brand-colour);
    color: $colour-grey-light;
  }
}

.accept_cookies_svg {
  padding: 20px;
}

@media (max-width: 768px) {
  .accept_cookies_modal {
    .modal-dialog {
      margin: calc(50vh - 192px) auto;
      width: 100%;
      padding: 15px;      
    }
  }

  .accept_cookies_column {
    margin-right: 10px;
    margin-left: 10px;
    p {
      padding-bottom: 15px;
    }
  }
}