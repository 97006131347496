@import '../App/InvestoristColours';

.dialogWindow {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.dialogWindow .modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: $colour-primary-dark;
  height: 600px;
}

.dialogWindow .modal-header {
  position: absolute;
  top: 0px;
  width: 100%;
  border-bottom: none;
  z-index: 100;
  padding: 20px 20px 0 0;
}

.dialogWindow .modal-header .skip {
  border: none !important;
  background-color: $colour-primary-dark !important;
  color: $colour-grey-mid !important;
  float: right;
}

.dialogWindow .modal-header .skip:hover {
  color: $colour-grey-mid !important;
  background-color: $colour-primary-dark !important;
}

.dialogWindow .modal-header .skip:active {
  box-shadow: none;
}

.dialogWindow .modal-body {
  position: absolute;
  top: 60px;
  padding: 0;
}

.dialogWindow .modal-body .imageSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: $colour-primary-dark;
}

.dialogWindow .modal-body .imageSection img {
  max-height: 315px;
  object-fit: contain;
}

.dialogWindow .modal-body .row {
  margin: 20px 0;
}

.dialogWindow .modal-body h1 {
  color: white;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

.dialogWindow .modal-body p {
  color: $colour-grey-mid;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.dialogWindow .modal-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: none;
  padding: 0 0 25px 0;
  text-align: center;
}

.dialogWindow .modal-footer button.explore,
.dialogWindow .modal-footer button.getStarted {
  padding: 10px 15px;
  color: $colour-primary-dark !important;
  background-color: white !important;
}

.dialogWindow .modal-footer .previous {
  float: left;
}

.dialogWindow .modal-footer .next {
  float: right;
}

.dialogWindow .modal-footer .next,
.dialogWindow .modal-footer .previous {
  color: colour-grey-mid !important;
  background-color: $colour-primary-dark !important;
}

.dialogWindow .modal-footer .next:active,
.dialogWindow .modal-footer .previous:active {
  box-shadow: none;
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 95%;
  }

  .dialogWindow .modal-content {
    height: 450px;
  }

  .dialogWindow .modal-header {
    padding-right: 0;
  }

  .dialogWindow .modal-body .imageSection img {
    max-height: 180px;
  }

  .dialogWindow .modal-body .textSection {
    padding: 0 5% 0 5%;
  }

  .dialogWindow .modal-footer button.next,
  .dialogWindow .modal-footer button.previous {
    width: 20%;
  }

  .dialogWindow .modal-footer button.explore,
  .dialogWindow .modal-footer button.getStarted {
    width: 40%;
  }
}
