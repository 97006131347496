@import '../App/InvestoristColours';

.radioBlock {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.radioBlockWrapper {
  display: block;
  cursor: pointer;
  border: 1px solid $colour-grey-light;
  color: white;
  padding: 10px;
  text-align: center;
  margin-bottom: 0;
}
.radioBlockWrapper h3 {
  font-size: 18px;
}
.radioBlockWrapper p {
  font-weight: normal;
}

.radioBlock:active,
.radioBlockWrapperSelected,
.radioBlockWrapper:hover {
  background: $colour-primary !important;
}

.radioBlock:active h3,
.radioBlock:active p,
.radioBlockWrapperSelected h3,
.radioBlockWrapperSelected p,
.radioBlockWrapper:hover h3,
.radioBlockWrapper:hover p {
  color: white !important;
}

.radioBlockInput {
  display: none;
}

.formLabel {
  margin-bottom: 5px;
  text-align: left;
}

.signupDialog .modal-body {
  padding: 0 30px 30px;
}

.signupDialog p {
  text-align: left;
}

.signupDialog .formLabel {
  color: $colour-secondary-mid;
}

.signupDialog .labelDesc {
  font-size: 12px;
}

.signupDialog .flagIcon {
  max-width: 20px;
  max-height: 20px;
}

.signupDialog .react-phone-number-input__icon {
  border: none;
  width: 25px;
  margin-top: -20px;
}

.signupDialog .react-phone-number-input__input.react-phone-number-input__phone::placeholder {
  color: $colour-input-placeholder-text;
}

.signupDialog .react-phone-number-input__country-select {
  height: 20px;
  margin-top: -10px;
}

.signupDialog .react-phone-number-input__country-select-arrow {
  margin-top: -10px;
}

.signupDialog .react-phone-number-input__icon-image {
  max-height: none;
}

.signupDialog .react-phone-number-input__input  {
  margin-top: -10px;
  border-bottom: none;
}

.signupDialog .react-phone-number-input--focus  {
  border-color: $colour-input-focus;
}

@media (min-width: 992px) {
  .signupDialog .modal-dialog {
    width: 650px !important;
  }
}
